<template>
  <div id="search-container">
    <Head :showIcon="false" />
    <van-search class="search" v-model="value" @search="onSearch" placeholder="Please enter a search keyword" />
    <van-list
      class="list van-clearfix"
      v-model:loading="loading"
      :finished="finished"
      :immediate-check="false"
      loading-text="Loading"
      finished-text="No More"
      @load="onLoad"
    >
      <div class="list-div van-clearfix">
        <div class="item" v-for="(item,key) in list" :key="key" @click="goGoods(item.goods_no)">
          <van-image
            fit="cover"
            width="90%"
            height="15rem"
            :src="imgUrl + item.image.image_path"
          />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Head from '@/components/head';
export default {
  data() {
    return {
      imgUrl: "http://img.kar-studio.com",
      value: "",
      loading: false,
      finished: false,
      list: [],
      page: 1,
      pageSize: 8,
    }
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
    onSearch(value) {
      this.value = value;
      this.list = [];
      this.page = 1;
      this.loading = false;
      this.loading = false;
      this.onLoad();
    },
    goGoods(goods_no) {
      this.$router.push({path: "/goods", query: {goods_no}})
    },
    onLoad() {
      this.loading = true;
      this.$api
        .goodsList({
          type: 0,
          category_id: 0,
          column_id: 0,
          name: this.value,
          page: this.page,
        })
        .then((res) => {
          if (res.data.status) {
            let data = res.data.data.data;
            this.list = this.list.concat(data);
            this.loading = false;
            this.page++;
            if (data.length < this.pageSize) {
              this.finished = true;
            }
          }
        });
    },
  },
  created() {
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
#search-container {
  width: 100%;
  .search {
    margin-top: 140px;
  }
  .list {
    width: 100%;
    height: calc(100vh - 140px - 120px);
    overflow-x: hidden;
    .list-div {
      width: 100%;
      overflow-x: hidden;
      .item {
        width: 50%;
        float: left;
        margin-top: 20px;
        img {
          display: block;
          margin: 0 auto;
        }
        p {
          width: 90%;
          font-size: 20px;
          margin: 0 auto;
          display: block;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
